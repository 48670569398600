
import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
import { computed, defineComponent, onActivated, onMounted, reactive, ref, toRef, watch } from 'vue'
import usePagination from '@/utils/composables/usePagination'
import { UserRole } from '@/types/Role'
import { Buttons, Inputs, TableColumns } from '@/types/BasicComponent'
import BasicTablePrintAndExport from '@/components/BasicPageLayout/BasicTablePrintAndExport.vue'
import { getJhbAccessToken, getJhbcConfigList, getJhbcList, JhbcConfigQueryParams, QueryJhbcListParams } from '@/api/jhbc'
import JHBCConfigDetailDialog from '@/views/jhbc/JHBCConfigDetailDialog.vue'
import useAreaCascaderProps from '@/utils/composables/useAreaCascaderProps'
import { getGoodsGroupPage } from '@/api/goods'
import { getAreaListOfGx } from '@/utils/common'

export default defineComponent({
  components: {
    BasicTablePage,
    BasicTablePrintAndExport,
    JHBCConfigDetailDialog,
  },
  setup() {
    const pagination = usePagination()
    const searchData = reactive<JhbcConfigQueryParams>({})
    const tableData = ref<AnyObject[]>([])
    const badgeValues = reactive<AnyObject>({})

    const selectedTableData = ref<AnyArray>([])
    const selectCount = computed(() => selectedTableData.value.length)

    const getTableData = async () => {
      const res = await getJhbcConfigList(Object.assign({ pageNo: pagination.currentPage, pageSize: pagination.pageSize }, searchData))
      tableData.value = res.data.data.records
      pagination.total = res.data.data.total
      patchTableGoodsGroupName()
    }
    pagination.setCallback(getTableData)

    onMounted(() => {
      getTableData()
    })

    const goodsGroupList = ref<AnyArray>([])
    onActivated(() => {
      getGoodsGroupData()
    })

    const patchTableGoodsGroupName = () => {
      tableData.value.forEach(td => {
        const goodsGroupId = td.goodsGroupId
        const goodsGroupMatch = goodsGroupList.value.find(ggl => ggl.id == goodsGroupId)
        td.goodsGroupName = goodsGroupMatch?.name
      })
    }
    const getGoodsGroupData = () => {
      getGoodsGroupPage({ pageNo: 1, pageSize: 100 }).then(res => {
        goodsGroupList.value = res.data.data.records
        patchTableGoodsGroupName()
      })
    }

    const onSelectionChange = (selection: any[]) => selectedTableData.value = selection

    const exportRef = ref()
    const jhbcConfigDetailDialogRef = ref()

    // 园区级联选择
    const cascaderValue = ref<string[]>([])
    const searchInputs = reactive<Inputs>([
      {
        label: '胶合板厂名',
        type: 'input',
        placeholder: '请输入胶合板厂名',
        clearable: true,
        model: toRef(searchData, 'jhbcName')
      }, {
        label: '区域',
        type: 'cascader',
        placeholder: '请选择区域',
        clearable: true,
        model: cascaderValue,
        props: useAreaCascaderProps(),
        showAllLevels: false,
      }
    ])
    watch(cascaderValue, val => {
      if (Array.isArray(val) && val.length > 0)
        searchData.areaCode = val[0]
      else searchData.areaCode = ''
    })

    const searchButtons = reactive<Buttons>([
      {
        label: '查询',
        icon: 'el-icon-search',
        onClick: getTableData
      }, {
        label: '导出',
        icon: 'el-icon-download',
        onClick: () => {
          exportRef.value.doExport()
        }
      }
    ])

    const tableColumns = reactive<TableColumns>([
      {
        prop: 'index',
        type: 'index',
        label: '序号',
        width: '60px',
        align: 'center',
        index: idx => pagination.pageOffset + idx
      }, {
        prop: 'jhbcName',
        label: '胶合板厂',
        minWidth: '300px',
      }, {
        prop: 'areaName',
        label: '区域',
        minWidth: '150px',
        align: 'center',
        preventFormat: true
      }, {
        prop: 'serviceFee',
        label: '服务费率(%)',
        minWidth: '120px',
        align: 'center',
        preventFormat: true
      }, {
        prop: 'sfCalcType',
        label: '是否含税',
        minWidth: '120px',
        type: 'status',
        align: 'center',
        statusType: [
          { label: '含税', value: 1, type: 'success' },
          { label: '不含税', value: 2, type: 'warning' },
        ]
      }, {
        prop: 'sfChargeType',
        label: '服务费收取方式',
        minWidth: '120px',
        type: 'status',
        align: 'center',
        statusType: [
          { label: '支付时扣除', value: 1, type: 'success' },
          { label: '开票时扣除', value: 2, type: 'warning' },
        ]
      }, {
        prop: 'sfAutoPayDays',
        label: '服务费自动扣除时间（天）',
        minWidth: '120px',
        align: 'center',
      }, {
        prop: 'goodsGroupName',
        label: '货物分组',
        minWidth: '150px',
        align: 'center',
      }, {
        type: 'button',
        label: '操作',
        fixed: 'right',
        buttons: [
          {
            label: '修改',
            onClick: scope => {
              jhbcConfigDetailDialogRef.value.show(scope.row)
            }
          }
        ]
      }
    ])

    return {
      getTableData,
      onSelectionChange,
      jhbcConfigDetailDialogRef,
      goodsGroupList,
      searchInputs,
      searchButtons,
      tableColumns,
      pagination,
      badgeValues,
      tableData,
      UserRole,
      selectCount,
      exportRef
    }
  }
})
