import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_BasicFormDialog = _resolveComponent("BasicFormDialog")!

  return (_openBlock(), _createBlock(_component_BasicFormDialog, {
    modelValue: _ctx.modalShow,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.modalShow) = $event)),
    title: "胶合板厂配置详情",
    footerButtons: _ctx.footerButtons,
    width: "1200px",
    "label-width": "125px",
    top: "7vh",
    closeOnClickModal: true
  }, {
    body: _withCtx(() => [
      _createVNode(_component_el_form, {
        class: "dialog-form",
        model: _ctx.formData,
        ref: "formRef",
        "label-width": "130px",
        inline: true,
        rules: _ctx.formRules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            prop: "jhbcName",
            size: "small",
            label: "胶合板厂"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                disabled: true,
                modelValue: _ctx.jhbcName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.jhbcName) = $event)),
                style: {"width":"100%"}
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "serviceFee",
            size: "small",
            label: "服务费率(%)"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.formData.serviceFee,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.serviceFee) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "sfCalcType",
            size: "small",
            label: "是否含税"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.formData.sfCalcType,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.sfCalcType) = $event)),
                style: {"width":"200px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_option, {
                    label: "含税",
                    value: 1
                  }),
                  _createVNode(_component_el_option, {
                    label: "不含税",
                    value: 2
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "sfChargeType",
            size: "small",
            label: "服务费收取方式"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.formData.sfChargeType,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.sfChargeType) = $event)),
                style: {"width":"200px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_option, {
                    label: "支付时扣除",
                    value: 1
                  }),
                  _createVNode(_component_el_option, {
                    label: "开票时扣除",
                    value: 2
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "sfAutoPayDays",
            size: "small",
            label: "服务费自动扣除时间（天）"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.formData.sfAutoPayDays,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.sfAutoPayDays) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "goodsGroupId",
            size: "small",
            label: "货物分组"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.formData.goodsGroupId,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.goodsGroupId) = $event)),
                style: {"width":"200px"}
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goodsGroupList, (goodsGroup) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: goodsGroup.id,
                      label: goodsGroup.name,
                      value: goodsGroup.id
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modelValue", "footerButtons"]))
}