
import { computed, defineComponent, nextTick, onMounted, PropType, ref, toRefs, watch } from 'vue'
import { ElMessage, ElMessageBox, ElImageViewer } from 'element-plus'
import { UploadFileExtend } from '@/types/BasicComponent'

let counter = 0

export default defineComponent({
  components: {
    ElImageViewer
  },
  props: {
    fileList: {
      type: Array as PropType<Partial<UploadFileExtend>[]>,
      default: []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disableDelete: {
      type: Boolean,
      default: false
    },
    disableAdd: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: 'image/jpeg, image/jpg, image/png'
    },
    limit: {
      type: [String, Number],
    },
    inline: {
      type: Boolean,
      default: false
    },
    size: {
      type: String as PropType<'small' | 'medium' | 'large'>,
      default: 'small'
    },
    icon: {
      type: String,
      default: 'el-icon-plus'
    },
    onFileDelete: {
      type: Function as PropType<(file: UploadFileExtend) => Promise<void>>
    },
    onFileAdd: {
      type: Function as  PropType<(file: UploadFileExtend) => Promise<void>>
    },
    onFileChange: {
      type: Function
    },
    singlePicture: {
      type: Boolean,
      default: false
    },
    onLoadImageError: {
      type: Function
    },
    onLoadImageSuccess: {
      type: Function
    }
  },
  emits: ['file-change'],
  setup(props, {emit}) {
    counter ++

    const fileList = ref(props.fileList)

    watch(() => props.fileList.length, () => {
      fileList.value = props.fileList
      // fileList.value.forEach((f, index) => {
      //   f._uid = index
      // })
    }, {immediate: true})

    const imageViewerVisible = ref(false)
    const viewerUrlList = ref<string[]>([])

    const clearImageList = () => {
      fileList.value.length = 0
    }

    const fileSuffix = ref('')
    const onLoadImageError = props.onLoadImageError? props.onLoadImageError: (event: any, file: UploadFileExtend) => {
      file.isNotImage = true
      const suffixList = ['pdf']
      const fileName = file.name || file.url || ''
      const fileNameSpl = fileName.split('.')
      const suffix = fileNameSpl[fileNameSpl.length - 1] || ''
      fileSuffix.value = suffixList.indexOf(suffix) >= 0? suffix: 'file'
    }

    const onDelete = (file: UploadFileExtend) => {
      ElMessageBox.confirm('是否确认删除图片？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const onFileDelete = props.onFileDelete

        const handleDelete = () => {
          const fl = fileList.value.slice()
          const delIndex = fileList.value.findIndex(ul => ul.uid === file.uid)

          if (delIndex !== -1) fl.splice(delIndex, 1)
          fileList.value = fl
          ElMessage.success?.('删除成功')
          onFileChange(file, fileList.value)
        }

        if (!onFileDelete) {
          handleDelete()
          return
        }

        onFileDelete(file).then(handleDelete)
      })
    }

    const openPreview = (file: UploadFileExtend) => {
      if (file.isNotImage) {
        window.open(file.url)
        return
      }
      const urlList = fileList.value.slice()
      const imgIndex = urlList.findIndex(ul => ul.uid === file.uid)
      console.log(file, urlList)
      viewerUrlList.value = urlList.splice(imgIndex).concat(urlList).map(item => item.url) as string[]
      imageViewerVisible.value = true
    }

    const closePreivew = () => {
      viewerUrlList.value = []
      imageViewerVisible.value = false
    }

    const showUpload = ref(true)
    const onFileChange = async (file: UploadFileExtend, fList: Partial<UploadFileExtend>[]) => {
      if (props.singlePicture) {
        const f = fList[fList.length - 1]
        props.onFileChange?.(file, [f])
        fileList.value = [f]
      } else {
        const fileIndex = fList.findIndex(ul => ul.uid === file.uid)
        if (props.onFileAdd && fileIndex >= 0) {
          try {
            await props.onFileAdd(file)
            fileList.value = fList
            ElMessage.success?.('上传成功')
            props.onFileChange?.(file, fList)
          } catch {
            fList.splice(fileIndex, 1)
            fileList.value.splice(fileIndex, 1)
          }
        }
      }
    }

    const uploadRef = ref(null)

    return {
      fileList,
      imageViewerVisible,
      viewerUrlList,
      openPreview,
      closePreivew,
      clearImageList,
      onDelete,
      onFileChange,
      onLoadImageError,
      uploadRef,
      showUpload,
      fileSuffix,
      counter
    }
  }
})
