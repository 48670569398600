
import BasicFormDialog from "@/components/BasicPageLayout/BasicFormDialog.vue";
import BasicInputGroup from '@/components/BasicPageLayout/BasicInputGroup.vue'
import BasicImageList from '@/components/BasicPageLayout/BasicImageList.vue'
import { Buttons, UploadFileExtend } from "@/types/BasicComponent";
import { defineComponent, PropType, reactive, ref, watch } from "vue";
import { FileType } from "@/types/Common";
import { updateJhbConfig, UpdateJhbConfigData } from "@/api/jhbc";
import { copyObject } from "@/utils/common";
import { ElMessage } from "element-plus";
import { FormRulesMap } from "element-plus/lib/components/form/src/form.type";
import commonValidate from '@/utils/validate'

export default defineComponent({
  components: {
    BasicFormDialog,
    BasicInputGroup,
    BasicImageList,
  },
  props: {
    goodsGroupList: {
      type: Array as PropType<AnyArray>,
      default: []
    }
  },
  emits: ['on-success'],
  setup(props, { emit }) {
    const formData = reactive<UpdateJhbConfigData>({
      jcid: 0,
      serviceFee: 0,
      sfCalcType: 0,
      sfChargeType: 0,
      sfAutoPayDays: 0,
      goodsGroupId: 0
    })

    const modalShow = ref(false)
    const jhbcName = ref('')

    const show = async (row: AnyObject) => {
      modalShow.value = true

      copyObject(formData, row)
      jhbcName.value = row.jhbcName
    }

    const formRules = reactive<FormRulesMap>({
      serviceFee: [
        { required: true, message: '请输入服务费率' },
        { type: 'number', validator: (rules, value) => commonValidate.range(value, [0, 100]), message: '请输入正确的服务费率' }
      ],
      sfCalcType: [
        { required: true, message: '请选择是否含税' },
      ],
      sfChargeType: [
        { required: true, message: '请选择服务费收取方式' },
      ],
      sfAutoPayDays: [
        { required: true, message: '请输入服务费自动扣除时间' },
        { type: 'number', validator: (rules, value) => commonValidate.range(value, [1, 90]), message: '服务费自动扣除时间必须在1-90天之间' }
      ],
      goodsGroupId: [
        { required: true, message: '请选择胶合板厂货物分组' },
      ],
    })

    const formRef = ref()
    const footerButtons = reactive<Buttons>([
      {
        type: 'primary',
        label: '保存',
        onClick: async () => {
          await formRef.value.validate()
          await updateJhbConfig(formData)
          ElMessage.success('保存成功')
          modalShow.value = false
          emit('on-success')
        }
      }, {
        type: 'default',
        label: '关闭',
        onClick: () => modalShow.value = false
      }
    ])

    return {
      jhbcName,
      formData,
      FileType,
      modalShow,
      footerButtons,
      formRules,
      show,
      formRef
    }
  }
})
